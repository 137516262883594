import * as React from "react"
import { Link, graphql, navigate } from 'gatsby';
import { siteMetadata } from "../../gatsby-config";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import FrontendPage from "../templates/page";

const Director = () => {

  React.useEffect(() => {
    if (!window.location.pathname.includes(siteMetadata.homePage)) {
      navigate(siteMetadata.homePage)
    }
  });

  return (
    <div />  
  )
}

// markup
const IndexPage = () => {

  // React.useEffect(() => {
  //   navigate(siteMetadata.homePage)
  // });

  return (
    <Director />
  )
}


export default IndexPage
